import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Wrapper } from './styles';
import { ContentfulSection } from '../types.d';
import { ContentfulFigure } from '@/types';

export interface HeroSection extends ContentfulSection {
  image: ContentfulFigure;
}

/**
 * Hero section
 */
const Hero: FC<HeroSection> = ({ image: { image } }) => (
  <Wrapper>
    <GatsbyImage image={getImage(image.gatsbyImageData)} alt={image.title} />
  </Wrapper>
);

export default Hero;
